import { Box, Chip, FormControl, InputLabel, MenuItem, Select, Stack, useTheme } from '@mui/material';
import { useCallback, useEffect, useRef } from 'react';
import { Journal, Organization } from '../api';
import { SMALL_HORIZONTAL_SPACING } from '../theme';
import { getFiscalYear } from '../utils/date-utils';
import { storage } from '../utils/storage';

const LOCAL_STORAGE_JOURNAL_KEY = 'admin.journal';

export interface AdminJournalSelectProps {
  showNone?: boolean;
  unreviewedCounts?: { [fy: string]: number } | null;
  organization: Organization | null;
  journals: Journal[];
  selectedJournal: Journal | null;
  onJournalChange: (journal: Journal | null) => void;
}
export function AdminJournalSelect({
  showNone,
  unreviewedCounts,
  journals,
  organization,
  selectedJournal,
  onJournalChange,
}: AdminJournalSelectProps) {
  const theme = useTheme();

  const changeJournal = useCallback(
    async (journalId: string) => {
      const journal = journals.find((j) => j.id === journalId);
      if (!journal && journalId !== 'none') {
        return;
      }

      if (journalId === 'none') {
        await storage.removeItem(LOCAL_STORAGE_JOURNAL_KEY);
      } else {
        await storage.setItem(LOCAL_STORAGE_JOURNAL_KEY, journalId);
      }

      onJournalChange(journal || null);
    },
    [onJournalChange, journals]
  );

  const journalsRef = useRef<Journal[] | null>(null);
  const organizationRef = useRef<Organization | null>(null);
  useEffect(() => {
    if (journalsRef.current === journals && organizationRef.current === organization) {
      return;
    }

    journalsRef.current = journals;
    organizationRef.current = organization;

    const handleNewJournals = async () => {
      const savedJournal = await storage.getItem(LOCAL_STORAGE_JOURNAL_KEY);
      if (savedJournal) {
        await changeJournal(savedJournal);
      } else if (journals.length > 0 && organization && journals[0].organizationId === organization.id) {
        const currentFY = getFiscalYear(new Date(), organization.fyEndMonth);
        const fyJournal = journals.find((j) => j.fy === currentFY)!;
        await changeJournal(fyJournal.id);
      }
    };

    handleNewJournals().catch((e) => {
      throw e;
    });
  }, [journals, changeJournal, organization]);

  let selectValue: string;
  if (selectedJournal) {
    selectValue = selectedJournal.id;
  } else if (showNone) {
    selectValue = 'none';
  } else {
    selectValue = '';
  }

  let otherJournalUnreviewedCount: number | null = null;
  if (unreviewedCounts) {
    otherJournalUnreviewedCount = Object.entries(unreviewedCounts)
      .filter(([fy, _]) => (selectedJournal ? fy !== selectedJournal?.fy : fy !== 'none'))
      .reduce((sum, [_, count]) => {
        return sum + count;
      }, 0);
  }

  return (
    <Stack direction='row' alignItems='center'>
      <FormControl size='small'>
        <InputLabel id='journal-select-label'>FY</InputLabel>
        <Select
          label='FY'
          labelId='journal-select-label'
          autoWidth
          value={selectValue}
          onChange={(event) => changeJournal(event.target.value)}
          style={{
            minWidth: theme.spacing(20),
          }}
        >
          {showNone && (
            <MenuItem value='none'>
              <Box display='inline'>None</Box>
              {unreviewedCounts && unreviewedCounts['none'] && (
                <Box display='inline' marginLeft={theme.spacing(SMALL_HORIZONTAL_SPACING)}>
                  <Chip
                    size='small'
                    sx={{ maxHeight: '1rem', fontSize: (theme.typography.small as { fontSize: string }).fontSize }}
                    label={`${unreviewedCounts['none']} Unreviewed`}
                  />
                </Box>
              )}
              <Box></Box>
            </MenuItem>
          )}
          {journals
            .sort((a, b) => a.fy.localeCompare(b.fy))
            .map((j) => (
              <MenuItem key={j.id} value={j.id}>
                <Box display='inline'>{j.fy}</Box>
                {unreviewedCounts && unreviewedCounts[j.fy] && (
                  <Box display='inline' marginLeft={theme.spacing(SMALL_HORIZONTAL_SPACING)}>
                    <Chip
                      size='small'
                      sx={{ maxHeight: '1rem', fontSize: (theme.typography.small as { fontSize: string }).fontSize }}
                      label={`${unreviewedCounts[j.fy]} Unreviewed`}
                    />
                  </Box>
                )}
              </MenuItem>
            ))}
        </Select>
      </FormControl>

      {!!otherJournalUnreviewedCount && (
        <Chip
          size='small'
          sx={{ maxHeight: '1rem', fontSize: (theme.typography.small as { fontSize: string }).fontSize }}
          label={`${otherJournalUnreviewedCount} in other FYs`}
        />
      )}
    </Stack>
  );
}
