import * as Sentry from '@sentry/browser';
import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import jwtDecode from 'jwt-decode';
import { createContext, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Organization, isMobile } from '.';
import { storage } from '../utils/storage';
import { axios } from './axios-base';

const ACCESS_TOKEN_KEY = 'accessToken';
const REFRESH_TOKEN_KEY = 'refreshToken';

export enum Role {
  CONTRACTOR = 'CONTRACTOR',
  INTERNATIONAL_CONTRACTOR = 'INTERNATIONAL_CONTRACTOR',
  EMPLOYEE = 'EMPLOYEE',
  OWNER = 'OWNER',
  ADMIN = 'ADMIN',
}

export interface Session {
  userId: string;
  organizationId: string;
  emailIdentifier: string;
  firstName?: string;
  lastName?: string;
  isRegistered: boolean;
  isVerified: boolean;
  role: Role;
  csrf: string;
  timeZone?: string;
}

interface Token {
  token: string;
  expMs: number;
}

export interface ISessionContext {
  sessionLoaded: boolean;
  session: Session | null | undefined;
  organization: Organization | null;
  sessionDiff: { old: Session | null | undefined; new: Session | null | undefined };
  token: Token | null | undefined;
  createUnregisteredUser: () => Promise<{ id: string }>;
  startUnregisteredSession: (userId: string) => Promise<void>;
  createRegisteredUser: (firstName: string, lastName: string, email: string, password: string) => Promise<void>;
  startRegisteredSession: (email: string, password: string) => Promise<void>;
  logout: () => Promise<void>;
  verifyAccount: (email: string, verificationCode: string) => Promise<void>;
  resendVerificationCode: (email: string) => Promise<void>;
  sendResetPasswordRequest: (email: string) => Promise<void>;
  resetPassword: (token: string, password: string) => Promise<void>;
  registerAccount: (token: string, firstName: string, lastName: string, password: string) => Promise<void>;
  recordDeviceToken: (deviceToken: string) => Promise<void>;
}

export function useSessionData() {
  const [token, setToken] = useState<Token | null | undefined>(undefined);
  const [session, setSession] = useState<Session | null | undefined>(undefined);
  const [organization, setOrganization] = useState<Organization | null>(null);

  const refreshPromise = useRef<Promise<AxiosResponse> | null>(null);

  const setAuthorization = useCallback(async (token: Token) => {
    const session = jwtDecode<Session>(token.token);
    Sentry.setUser({
      ...session,
      csrf: undefined, //Prevent these from showing up in logs
    });

    if (isMobile) {
      await storage.setSecureItem(ACCESS_TOKEN_KEY, JSON.stringify(token));
    } else {
      await storage.setItem(ACCESS_TOKEN_KEY, JSON.stringify(token));
    }

    axios.defaults.headers.common['Authorization'] = `Bearer ${token.token}`;
    axios.defaults.headers.common['X-CSRF-TOKEN'] = session.csrf;

    refreshPromise.current = null;

    setSession(session);
    setToken(token);
  }, []);

  const previousSessionValue = useRef<Session | null | undefined>(undefined);
  const [sessionDiff, setSessionDiff] = useState<{ old: Session | null | undefined; new: Session | null | undefined }>({
    old: undefined,
    new: undefined,
  });
  useEffect(() => {
    setSessionDiff((existing) => {
      previousSessionValue.current = existing.new;

      return {
        old: existing.new,
        new: session,
      };
    });
  }, [session]);

  const fetchOrganization = useCallback(async (organizationId: string) => {
    try {
      const response = await axios.get(`/organizations/${organizationId}`);
      const org = response.data as Organization;
      setOrganization(org);
    } catch (e) {
      setOrganization(null);
    }
  }, []);

  useEffect(() => {
    const fetch = async () => {
      if (session) {
        await fetchOrganization(session.organizationId);
      } else {
        setOrganization(null);
      }
    };

    fetch().catch((e) => {
      throw e;
    });
  }, [session, fetchOrganization]);

  const createUnregisteredUser = useCallback(async () => {
    const unregisteredUserResponse = await axios.post('/unregistered-users', {
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    });

    return unregisteredUserResponse.data as { id: string };
  }, []);

  const startUnregisteredSession = useCallback(
    async (userId: string) => {
      const tokenResponse = await axios.post('/session', {
        unregisteredUserId: userId,
      });

      const tokens = tokenResponse.data as { accessToken: Token; refreshToken: Token };

      await setAuthorization(tokens.accessToken);

      if (isMobile) {
        await storage.setSecureItem(REFRESH_TOKEN_KEY, tokens.refreshToken.token);
      }
    },
    [setAuthorization]
  );

  const createRegisteredUser = useCallback(
    async (firstName: string, lastName: string, email: string, password: string) => {
      const existingUserId = session ? session.userId : null;

      await axios.post('/users', {
        existingUserId,
        firstName,
        lastName,
        email,
        password,
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      });
    },
    [session]
  );

  const startRegisteredSession = useCallback(
    async (email: string, password: string) => {
      const tokensResponse = await axios.post('/session', {
        email,
        password,
      });

      const tokens = tokensResponse.data as { accessToken: Token; refreshToken: Token };

      await setAuthorization(tokens.accessToken);

      if (isMobile) {
        await storage.setSecureItem(REFRESH_TOKEN_KEY, tokens.refreshToken.token);
      }
    },
    [setAuthorization]
  );

  const verifyAccount = useCallback(
    async (email: string, verificationCode: string) => {
      const tokensResponse = await axios.post('/verify-account', {
        email,
        verificationCode,
      });

      const tokens = tokensResponse.data as { accessToken: Token; refreshToken: Token };

      await setAuthorization(tokens.accessToken);

      if (isMobile) {
        await storage.setSecureItem(REFRESH_TOKEN_KEY, tokens.refreshToken.token);
      }
    },
    [setAuthorization]
  );

  const resendVerificationCode = useCallback(async (email: string) => {
    await axios.post('/resend-verification', {
      email,
    });
  }, []);

  const dropSession = useCallback(() => {
    Sentry.setUser(null);
    axios.defaults.headers.common['Authorization'] = null;
    axios.defaults.headers.common['X-CSRF-TOKEN'] = null;
    setToken(null);
    setSession(null);
  }, []);

  const logout = useCallback(async () => {
    dropSession();

    if (isMobile) {
      await storage.removeSecureItem(ACCESS_TOKEN_KEY);
      await storage.removeSecureItem(REFRESH_TOKEN_KEY);
    } else {
      await storage.removeItem(ACCESS_TOKEN_KEY);
    }

    await axios.delete('/session');
  }, [dropSession]);

  const refreshSession = useCallback(async () => {
    try {
      if (refreshPromise.current) {
        return refreshPromise.current;
      }

      if (isMobile) {
        const refreshToken = await storage.getSecureItem(REFRESH_TOKEN_KEY);

        refreshPromise.current = axios.post('/refresh-session', {
          refreshToken,
        });
      } else {
        refreshPromise.current = axios.post('/refresh-session');
      }

      const tokensResponse = await refreshPromise.current;

      const tokens = tokensResponse.data as { accessToken: Token; refreshToken: Token };

      await setAuthorization(tokens.accessToken);

      if (isMobile) {
        await storage.setSecureItem(REFRESH_TOKEN_KEY, tokens.refreshToken.token);
      }
    } catch (e) {
      await logout();
    }
  }, [setAuthorization, logout]);

  useEffect(() => {
    const interceptorId = axios.interceptors.response.use(
      (response) => response,
      async (error: AxiosError) => {
        const customConfig = error.config as AxiosRequestConfig & { _retry: boolean }; // Cast to custom interface

        if (error.response?.status === 401) {
          if (error.config?.url && !error.config.url.endsWith('/refresh-session') && !customConfig._retry) {
            customConfig._retry = true;

            await refreshSession();

            customConfig.headers!['Authorization'] = axios.defaults.headers.common['Authorization'];
            customConfig.headers!['X-CSRF-TOKEN'] = axios.defaults.headers.common['X-CSRF-TOKEN'];

            return await axios.request(customConfig);
          }
        } else {
          return Promise.reject(error);
        }
      }
    );

    return () => {
      axios.interceptors.response.eject(interceptorId);
    };
  }, [refreshSession]);

  const sendResetPasswordRequest = useCallback(async (email: string) => {
    await axios.post('/reset-password-request', {
      email,
    });
  }, []);

  const resetPassword = useCallback(
    async (token: string, password: string) => {
      await axios.post('/reset-password', {
        token,
        password,
      });

      await logout();
    },
    [logout]
  );

  const registerAccount = useCallback(
    async (token: string, firstName: string, lastName: string, password: string) => {
      const tokensResponse = await axios.post('/register', {
        token,
        firstName,
        lastName,
        password,
      });

      const tokens = tokensResponse.data as { accessToken: Token; refreshToken: Token };

      await setAuthorization(tokens.accessToken);

      if (isMobile) {
        await storage.setSecureItem(REFRESH_TOKEN_KEY, tokens.refreshToken.token);
      }
    },
    [setAuthorization]
  );

  const recordDeviceToken = useCallback(async (deviceToken: string) => {
    await axios.post('/device-token', {
      deviceToken,
    });
  }, []);

  useEffect(() => {
    const refresh = async () => {
      const storedAccessToken = isMobile ? await storage.getSecureItem(ACCESS_TOKEN_KEY) : await storage.getItem(ACCESS_TOKEN_KEY);

      if (!storedAccessToken) {
        setSession(null);
      }

      const refresh = () => {
        refreshSession().catch(() => {
          logout().catch((_e) => {
            throw new Error('Cannot drop session');
          });
        });
      };

      if (storedAccessToken) {
        const accessToken = JSON.parse(storedAccessToken) as Token;
        if (accessToken.expMs < Date.now()) {
          refresh();

          return;
        }
        await setAuthorization(accessToken);
      } else {
        refresh();
      }
    };

    refresh().catch((e) => {
      throw e;
    });
  }, [setAuthorization, logout, refreshSession]);

  useEffect(() => {
    if (token) {
      const twoMinutes = 1000 * 60 * 2;

      const timeout = setTimeout(
        async () => {
          if (tabFocused.current) {
            await refreshSession();
          }
        },
        token.expMs - Date.now() - twoMinutes
      );

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [token, startUnregisteredSession, refreshSession]);

  const tabFocused = useRef(true);
  useEffect(() => {
    if (isMobile) {
      return; // Mobile is never multi-tab. No need for these listeners
    }

    const focusListener = () => {
      tabFocused.current = true;
      if (!token || token.expMs < Date.now()) {
        refreshSession().catch((e) => {
          throw e;
        });
      }
    };

    const blurListener = () => {
      tabFocused.current = false;
    };

    const storageListener = (event: StorageEvent) => {
      if (event.storageArea !== localStorage) {
        return;
      }

      if (event.key === ACCESS_TOKEN_KEY && event.newValue) {
        const newToken = JSON.parse(event.newValue) as Token;
        const newSession = jwtDecode<Session>(newToken.token);

        if (session && newSession.userId !== session.userId) {
          dropSession();
        }
      }
    };

    window.addEventListener('focus', focusListener);
    window.addEventListener('blur', blurListener);
    window.addEventListener('storage', storageListener);

    return () => {
      window.removeEventListener('focus', focusListener);
      window.removeEventListener('blur', blurListener);
      window.removeEventListener('storage', storageListener);
    };
  }, [session, dropSession, token, setAuthorization, refreshSession]);

  const sessionContext: ISessionContext = {
    organization,
    session,
    sessionDiff,
    sessionLoaded: session !== undefined,
    token,
    createUnregisteredUser,
    startUnregisteredSession,
    createRegisteredUser,
    startRegisteredSession,
    logout,
    verifyAccount,
    resendVerificationCode,
    sendResetPasswordRequest,
    resetPassword,
    registerAccount,
    recordDeviceToken,
  };

  return sessionContext;
}

export const SessionContext = createContext<ISessionContext>({} as ISessionContext);

export const useSession = () => useContext(SessionContext);

export const SessionContextProvider = ({ children }: { children: React.ReactNode }) => {
  const session = useSessionData();

  return <SessionContext.Provider value={session}>{children}</SessionContext.Provider>;
};
