import { Download, FolderZip } from '@mui/icons-material';
import {
  Alert,
  Badge,
  Box,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  Link,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
  Popover,
  Stack,
  Tab,
  Tabs,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { DataGrid, GridAlignment, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { format } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import {
  ArrowSwapHorizontal,
  BackwardItem,
  Book,
  CalendarCircle,
  Category2,
  Clock,
  CloseCircle,
  Copy,
  CopySuccess,
  DocumentUpload,
  Edit,
  Eye,
  EyeSlash,
  Filter,
  InfoCircle,
  LinkCircle,
  Notepad2,
  Receipt,
  RefreshCircle,
  TickCircle,
  Warning2,
} from 'iconsax-react';
import { createContext, CSSProperties, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import {
  Account,
  CheckInStatus,
  Document,
  DocumentType,
  FileArchive,
  FileArchiveStatus,
  Journal,
  JournalEntry,
  Organization,
  OtterProcessingStatus,
  TransactionDocumentMatch,
  useAdmin,
  User,
} from '../../../api/index.tsx';
import {
  AdminJournalSelect,
  AdminOrganizationSelect,
  Button,
  ConfirmDialog,
  PageBody,
  PageContainer,
  PageHeader,
  Search,
  ThreeColumn,
  TreeSelectDataGridCellEdit,
  TreeSelectDataGridCellView,
  useSelectedOrganization,
} from '../../../components/index.ts';
import { MED_HORIZONTAL_SPACING, SMALL_HORIZONTAL_SPACING, SMALL_VERTICAL_SPACING } from '../../../theme.ts';
import { formatAmount } from '../../../utils/currencies.ts';
import { useOneClickDataGridEditing } from '../../../utils/datagrid.ts';
import { getFiscalYear } from '../../../utils/date-utils.ts';
import { CheckInResultsDialog } from './check-in-results-dialog.tsx';
import { DocumentCategorizationDialog } from './document-categorization-dialog.tsx';
import { DocumentEditingDialog } from './document-editor.tsx';
import { DocumentNotesDialog } from './document-notes-dialog.tsx';

enum DocumentsTab {
  UNREVIEWED = 'UNREVIEWED',
  NEED_CLARIFICATION = 'NEED_CLARIFICATION',
  REVIEWED = 'REVIEWED',
  ALL_ACTIVE = 'ALL_ACTIVE',
  IGNORED = 'IGNORED',
  ALL = 'ALL',
}

function searchDocuments(documents: Document[], searchCriteria: string[]) {
  return documents.filter((d) => {
    if (!searchCriteria.length) {
      return true;
    }

    for (const searchCriterion of searchCriteria) {
      if (searchCriterion === d.id || searchCriterion === `id::${d.id}`) {
        return true;
      }

      const formattedDate = formatDocumentDate(d);
      if (formattedDate && (formattedDate.includes(searchCriterion.toLowerCase()) || searchCriterion.toLowerCase().includes(formattedDate))) {
        return true;
      }

      if (
        d.merchantName &&
        (searchCriterion.toLowerCase().includes(d.merchantName.toLowerCase()) || d.merchantName.toLowerCase().includes(searchCriterion.toLowerCase()))
      ) {
        return true;
      }

      if (d.currency) {
        const formattedAmount = formatAmount(d.afterTax, d.currency);
        if (formattedAmount && (formattedAmount.includes(searchCriterion.toLowerCase()) || searchCriterion.includes(formattedAmount))) {
          return true;
        }
      }
    }

    return false;
  });
}

const useAdminData = (selectedOrganization: Organization | null, selectedJournal: Journal | null) => {
  const {
    journals,
    journalEntries,
    organizations,
    users,
    documents,
    transactionDocumentMatches,
    journalAccounts,
    documentsMissingTransactions: documentsMissingTransactionsMap,
    fetchDocuments,
    fetchUnreviewedDocumentCounts,
    fetchJournals,
    fetchJournalEntries,
    fetchOrganizations,
    fetchOrganizationUsers,
    fetchTransactionDocumentMatches,
    fetchDocumentsMissingTransactions,
    fetchJournalAccounts,
    updateDocument: updateDocumentApi,
    prepareDocumentArchive: prepareDocumentArchiveApi,
    fetchFileArchives,
    uploadDocuments: uploadDocumentsApi,
    unreviewedDocumentCounts,
  } = useAdmin();

  const sortedOrganizations = useMemo(() => {
    return organizations ? organizations.sort((a, b) => a.name.localeCompare(b.name)) : null;
  }, [organizations]);

  useEffect(() => {
    fetchOrganizations().catch((e) => {
      throw e;
    });
  }, [fetchOrganizations]);

  useEffect(() => {
    if (!selectedOrganization) {
      return;
    }

    fetchJournals(selectedOrganization.id!).catch((e) => {
      throw e;
    });

    fetchUnreviewedDocumentCounts(selectedOrganization.id!).catch((e) => {
      throw e;
    });

    fetchDocuments(selectedOrganization.id!).catch((e) => {
      throw e;
    });

    fetchTransactionDocumentMatches(selectedOrganization.id!).catch((e) => {
      throw e;
    });

    fetchDocumentsMissingTransactions(selectedOrganization.id!).catch((e) => {
      throw e;
    });

    fetchOrganizationUsers(selectedOrganization.id!).catch((e) => {
      throw e;
    });
  }, [
    fetchJournals,
    fetchDocuments,
    fetchTransactionDocumentMatches,
    fetchDocumentsMissingTransactions,
    fetchOrganizationUsers,
    fetchUnreviewedDocumentCounts,
    selectedOrganization,
  ]);

  useEffect(() => {
    if (!selectedJournal) {
      return;
    }

    fetchJournalEntries(selectedJournal.id).catch((e) => {
      throw e;
    });
  }, [selectedJournal, fetchJournalEntries]);

  const orgJournals = useMemo(() => {
    if (!selectedOrganization) {
      return [];
    }

    const orgJournals = journals[selectedOrganization.id!];

    return orgJournals || [];
  }, [journals, selectedOrganization]);

  const selectedJournalEntries = useMemo(() => {
    if (!selectedJournal) {
      return null;
    }

    return journalEntries[selectedJournal.id] || [];
  }, [journalEntries, selectedJournal]);

  useEffect(() => {
    const promises = [];
    for (const journal of orgJournals) {
      promises.push(
        fetchJournalAccounts(journal.id).catch((e) => {
          throw e;
        })
      );
    }
  }, [orgJournals, fetchJournalAccounts]);

  const orgDocuments = useMemo(() => {
    if (!selectedOrganization) {
      return null;
    }

    const orgDocuments = documents[selectedOrganization.id!];

    return orgDocuments || null;
  }, [documents, selectedOrganization]);

  const matchesByDocumentId = useMemo(() => {
    if (!selectedOrganization || !transactionDocumentMatches[selectedOrganization.id!]) {
      return {};
    }

    return transactionDocumentMatches[selectedOrganization.id!]
      .flatMap((m) => m.matches)
      .reduce(
        (map, current) => {
          if (!map[current.documentId]) {
            map[current.documentId] = [];
          }

          map[current.documentId].push(current);

          return map;
        },
        {} as { [transactionId: string]: TransactionDocumentMatch[] }
      );
  }, [transactionDocumentMatches, selectedOrganization]);

  const accountsByFy = useMemo(() => {
    const map: { [fy: string]: Account[] } = {};

    for (const journal of orgJournals) {
      map[journal.fy] = journalAccounts[journal.id] || [];
    }

    return map;
  }, [orgJournals, journalAccounts]);

  const updateDocument = useCallback(
    async (oldDocument: Document, updates: Partial<Document> & { id: string }) => {
      if (!selectedOrganization) {
        return;
      }

      await updateDocumentApi(selectedOrganization.id!, updates.id, updates);

      if (selectedJournal) {
        await fetchJournalEntries(selectedJournal.id);
      }

      if (matchesByDocumentId[updates.id]) {
        await fetchTransactionDocumentMatches(selectedOrganization.id!);
      }

      await fetchDocumentsMissingTransactions(selectedOrganization.id!);

      if (oldDocument.otterProcessingStatus !== updates.otterProcessingStatus) {
        await fetchUnreviewedDocumentCounts(selectedOrganization.id!);
      }
    },
    [
      updateDocumentApi,
      selectedOrganization,
      fetchJournalEntries,
      selectedJournal,
      fetchTransactionDocumentMatches,
      matchesByDocumentId,
      fetchDocumentsMissingTransactions,
      fetchUnreviewedDocumentCounts,
    ]
  );

  const prepareDocumentArchive = useCallback(async () => {
    if (!selectedOrganization) {
      return;
    }

    await prepareDocumentArchiveApi(selectedOrganization.id!);
  }, [selectedOrganization, prepareDocumentArchiveApi]);

  const documentsMissingTransactions = useMemo(() => {
    if (!selectedOrganization || !documentsMissingTransactionsMap[selectedOrganization.id!]) {
      return null;
    }

    return documentsMissingTransactionsMap[selectedOrganization.id!];
  }, [documentsMissingTransactionsMap, selectedOrganization]);

  const uploadDocuments = useCallback(
    async (files: File[]) => {
      if (!selectedOrganization) {
        return;
      }

      await uploadDocumentsApi(selectedOrganization.id!, files);
    },
    [uploadDocumentsApi, selectedOrganization]
  );

  const orgUsers = useMemo(() => {
    if (!selectedOrganization) {
      return null;
    }

    return users[selectedOrganization.id!] || null;
  }, [selectedOrganization, users]);

  const orgUnreviewedDocumentCounts = useMemo(() => {
    if (!selectedOrganization || !unreviewedDocumentCounts[selectedOrganization.id!]) {
      return null;
    }

    return unreviewedDocumentCounts[selectedOrganization.id!];
  }, [selectedOrganization, unreviewedDocumentCounts]);

  return {
    journals: orgJournals,
    journalEntries: selectedJournalEntries,
    organizations: sortedOrganizations,
    users: orgUsers,
    documents: orgDocuments,
    matchesByDocumentId,
    updateDocument,
    accountsByFy,
    prepareDocumentArchive,
    fetchFileArchives,
    documentsMissingTransactions,
    uploadDocuments,
    unreviewedDocumentCounts: orgUnreviewedDocumentCounts,
  };
};

function formatDocumentDate(document: Document) {
  return document.date ? formatInTimeZone(document.date, 'utc', 'MMM d, yyyy') : 'Unknown';
}

function DocumentUpdateConfirmDialog({
  documents,
  documentChanges,
  matchesByDocumentId,
  onClose,
  onConfirm,
}: {
  documents: Document[] | null;
  documentChanges: {
    document: Document;
    changes: Partial<Document> & { id: string };
  } | null;
  matchesByDocumentId: { [documentId: string]: TransactionDocumentMatch[] };
  onClose: () => void;
  onConfirm: () => void;
}) {
  if (!documents) {
    return null;
  }

  let warningMessage = '';
  if (documentChanges?.document) {
    if (matchesByDocumentId[documentChanges.document.id]) {
      warningMessage =
        'This document is matched to one or more transactions. Editing the document will unmatch the document. Do you want to proceed?';
    } else if (documentChanges?.changes.ignored && documentChanges.document.transactionMatchHint) {
      warningMessage = `This document has a match hint indicating it's intended to be matched to transaction ${documentChanges.document.transactionMatchHint}. Are you sure you want to proceed?`;
    }
  }

  return <ConfirmDialog open={!!documentChanges} onClose={onClose} onConfirm={onConfirm} content={warningMessage} />;
}

interface DocumentsTableProps {
  tab: DocumentsTab;
  documents: Document[] | null;
  organization: Organization | null;
  users: User[] | null;
  journal: Journal | null;
  journalEntries: JournalEntry[] | null;
  accountsByFy: { [fy: string]: Account[] };
  matchesByDocumentId: { [documentId: string]: TransactionDocumentMatch[] };
  documentsMissingTransactions: Set<string> | null;
  onChangeDocument: (oldDocument: Document, changes: Partial<Document> & { id: string }) => Promise<void>;
  style?: CSSProperties;
}
function DocumentsTable({
  tab,
  documents,
  organization,
  users,
  journal,
  journalEntries,
  accountsByFy,
  matchesByDocumentId,
  documentsMissingTransactions,
  onChangeDocument,
  style,
}: DocumentsTableProps) {
  const theme = useTheme();

  const [documentsLoading, setDocumentsLoading] = useState(() => new Set<string>());

  const [checkInResultsDialogOpenFor, setCheckInResultsDialogOpenFor] = useState<Document | null>(null);

  const journalAccountsById = useMemo(() => {
    if (!journal || !accountsByFy[journal.fy]) {
      return {};
    }

    return accountsByFy[journal.fy].reduce(
      (map, current) => {
        map[current.id] = current;
        return map;
      },
      {} as { [accountId: string]: Account }
    );
  }, [journal, accountsByFy]);

  const [idCopied, setIdCopied] = useState<string | null>(null);
  const copy = useCallback(async (documentId: string) => {
    setIdCopied(documentId);
    await navigator.clipboard.writeText(documentId);
  }, []);

  const [editDocument, setEditDocument] = useState<Document | null>(null);
  const [queuedDocumentChanges, setQueuedDocumentChanges] = useState<{
    document: Document;
    changes: Partial<Document> & { id: string };
  } | null>(null);
  const changeDocumentOptimistic = useCallback(
    async (original: Document, changes: Partial<Document> & { id: string }) => {
      if (matchesByDocumentId[original.id] || (original.transactionMatchHint && changes.ignored)) {
        setQueuedDocumentChanges({
          document: original,
          changes,
        });
        return Promise.resolve<'CHANGED' | 'QUEUED'>('QUEUED');
      } else {
        setDocumentsLoading((existing) => {
          const newSet = new Set(existing);
          newSet.add(changes.id);
          return newSet;
        });

        onChangeDocument(original, changes)
          .catch((e) => {
            throw e;
          })
          .finally(() => {
            setDocumentsLoading((existing) => {
              const newSet = new Set(existing);
              newSet.delete(changes.id);
              return newSet;
            });
          });
      }
      setEditDocument(null);
      return Promise.resolve<'CHANGED' | 'QUEUED'>('CHANGED');
    },
    [onChangeDocument, matchesByDocumentId]
  );
  const changeDocument = useCallback(
    async (original: Document, changes: Partial<Document> & { id: string }) => {
      if (matchesByDocumentId[changes.id] || (original.transactionMatchHint && changes.ignored)) {
        setQueuedDocumentChanges({
          document: original,
          changes,
        });
        return 'QUEUED';
      } else {
        try {
          setDocumentsLoading((existing) => {
            const newSet = new Set(existing);
            newSet.add(changes.id);
            return newSet;
          });

          await onChangeDocument(original, changes);
        } finally {
          setDocumentsLoading((existing) => {
            const newSet = new Set(existing);
            newSet.delete(changes.id);
            return newSet;
          });
        }
      }
      setEditDocument(null);
      return 'CHANGED';
    },
    [onChangeDocument, matchesByDocumentId]
  );

  const [viewNotesForDocumentId, setViewNotesForDocumentId] = useState<string | null>(null);

  const approveDocument = useCallback(
    async (documentId: string) => {
      if (!documents) {
        return;
      }

      const document = documents.find((d) => d.id === documentId);
      if (!document) {
        return;
      }

      await changeDocument(document, {
        ...document,
        otterProcessingStatus: OtterProcessingStatus.REVIEWED,
      });
    },
    [changeDocument, documents]
  );

  const checkInSignOff = useCallback(
    async (documentId: string) => {
      if (!documents) {
        return;
      }

      const document = documents.find((d) => d.id === documentId);
      if (!document) {
        return;
      }

      await changeDocument(document, {
        ...document,
        checkInStatus: CheckInStatus.SIGNED_OFF,
      });
    },
    [changeDocument, documents]
  );

  const markDocumentAsUnreviewed = useCallback(
    async (documentId: string) => {
      if (!documents) {
        return;
      }

      const document = documents.find((d) => d.id === documentId);
      if (!document) {
        return;
      }

      await changeDocument(document, {
        ...document,
        otterProcessingStatus: OtterProcessingStatus.PARSED,
      });
    },
    [changeDocument, documents]
  );

  const ignoreMatchRequirement = useCallback(
    async (documentId: string) => {
      if (!documents) {
        return;
      }

      const document = documents.find((d) => d.id === documentId);
      if (!document) {
        return;
      }

      await changeDocument(document, {
        ...document,
        ignoreMatchRequirement: true,
      });
    },
    [changeDocument, documents]
  );

  const unignoreMatchRequirement = useCallback(
    async (documentId: string) => {
      if (!documents) {
        return;
      }

      const document = documents.find((d) => d.id === documentId);
      if (!document) {
        return;
      }

      await changeDocument(document, {
        ...document,
        ignoreMatchRequirement: false,
      });
    },
    [changeDocument, documents]
  );

  const confirmDocumentChanges = useCallback(async () => {
    if (queuedDocumentChanges) {
      try {
        setDocumentsLoading((existing) => {
          const newSet = new Set(existing);
          newSet.add(queuedDocumentChanges.changes.id);
          return newSet;
        });

        await onChangeDocument(queuedDocumentChanges.document, queuedDocumentChanges.changes);
      } finally {
        setDocumentsLoading((existing) => {
          const newSet = new Set(existing);
          newSet.delete(queuedDocumentChanges.changes.id);
          return newSet;
        });
      }
      setQueuedDocumentChanges(null);
    }
  }, [queuedDocumentChanges, onChangeDocument]);

  const viewJournalEntryForDocument = useCallback(
    (document: Document) => {
      if (!journalEntries) {
        return;
      }

      const journalEntriesForTransaction = journalEntries.filter((je) => je.tags.find((t) => t === `document::${document.id}`));
      if (journalEntriesForTransaction.length >= 1) {
        const journalEntryIdsParam = journalEntriesForTransaction.map((e) => `journalEntryIds=${e.id}`).join('&');
        window.open(`/admin/journals?${journalEntryIdsParam}`, '_blank');
      }
    },
    [journalEntries]
  );

  const [categorizationDialogOpenFor, setCategorizationDialogOpenFor] = useState<string | null>(null);

  const { handleCellClick, cellModesModel, handleCellModesModelChange } = useOneClickDataGridEditing();

  // const getPotentialErrors = (document: Document) => {
  //   const potentialErrors = [] as string[];
  //   if (document.valuesOverridden) {
  //     return potentialErrors;
  //   }

  //   if (document.beforeTax !== document.beforeTaxAlt) {
  //     potentialErrors.push('beforeTax');
  //   }

  //   if (document.afterTax !== document.afterTaxAlt) {
  //     potentialErrors.push('afterTax');
  //   }

  //   if (document.GST !== document.GSTAlt) {
  //     potentialErrors.push('GST');
  //   }

  //   if (document.HST !== document.HSTAlt) {
  //     potentialErrors.push('HST');
  //   }

  //   if (document.PST !== document.PSTAlt) {
  //     potentialErrors.push('PST');
  //   }

  //   if (document.currency !== document.currencyAlt) {
  //     potentialErrors.push('currency');
  //   }

  //   if (document.merchantName?.toLowerCase() !== document.merchantNameAlt?.toLowerCase()) {
  //     potentialErrors.push('merchantName');
  //   }

  //   if (document.date?.getTime() !== document.dateAlt?.getTime()) {
  //     potentialErrors.push('date');
  //   }

  //   return potentialErrors;
  // };

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      width: 50,
      renderCell: (params) => {
        const document = params.row as Document;
        const loading = documentsLoading.has(document.id);

        return (
          <Tooltip title={loading ? 'Loading' : document.id}>
            <span>
              {loading ? (
                <CircularProgress size='1rem' />
              ) : (
                <IconButton onClick={() => copy(document.id)}>
                  {idCopied === document.id ? (
                    <CopySuccess size='1.1rem' variant='Bold' color={theme.palette.primary.main} />
                  ) : (
                    <Copy size='1.1rem' variant='Outline' color={theme.palette.primary.main} />
                  )}
                </IconButton>
              )}
            </span>
          </Tooltip>
        );
      },
    },
    {
      field: 'date',
      headerName: 'Date (UTC)',
      width: 150,
      renderCell: (params) => {
        const document = params.row as Document;
        if (!document.date) {
          return 'Unknown';
        }
        return formatDocumentDate(document);
      },
    },
    {
      field: 'merchantName',
      headerName: 'Merchant Name',
      flex: 1,
    },
    {
      field: 'afterTax',
      headerName: 'After Tax Amount',
      width: 150,
      renderCell: (params) => {
        const document = params.row as Document;

        if (!document.currency) {
          return 'Unknown';
        }

        return formatAmount(document.afterTax, document.currency);
      },
    },
    {
      field: 'image',
      headerName: 'Image',
      renderCell: (params) => {
        const document = params.row as Document;

        const thumbnailUrl = document.thumbnailSignedUrl || '/pdf-file-icon.svg';

        return (
          <Box padding={theme.spacing(2)}>
            <Link href={document.signedUrl} target='_blank'>
              <img src={thumbnailUrl} alt={document.fileName} width='32px' height='32px' />
            </Link>
          </Box>
        );
      },
    },
    // {
    //   field: 'potentialErrors',
    //   headerName: 'Potential Errors',
    //   sortComparator: (_v1, _v2, params1, params2) => {
    //     // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    //     const documentA = params1.api.getRow(params1.id) as Document;
    //     // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    //     const documentB = params2.api.getRow(params2.id) as Document;

    //     const docAErrors = getPotentialErrors(documentA);
    //     const docBErrors = getPotentialErrors(documentB);

    //     return docAErrors.length - docBErrors.length;
    //   },
    //   sortable: true,
    //   renderCell: (params) => {
    //     const document = params.row as Document;

    //     const potentialErrors = getPotentialErrors(document);

    //     let severity = 'none';
    //     if (potentialErrors.length > 0 && potentialErrors.length < 4) {
    //       severity = 'warn';
    //     } else if (potentialErrors.length >= 4) {
    //       severity = 'error';
    //     }

    //     const color = severity === 'none' ? theme.palette.text.primary : severity === 'warn' ? theme.palette.warning.main : theme.palette.error.main;

    //     const errorList = potentialErrors.join(', ') || 'None';

    //     return (
    //       <Tooltip title={errorList}>
    //         <Typography color={color}>{potentialErrors.length <= 2 ? errorList : `${potentialErrors.length} fields`}</Typography>
    //       </Tooltip>
    //     );
    //   },
    // },
    {
      field: 'assignedCategory',
      headerName: 'Assigned Account',
      width: 150,
      editable: true,
      valueGetter: (params) => {
        if (!journal) {
          return null;
        }

        const accountId = params.value as string | null;
        return accountId ? journalAccountsById[accountId] : null;
      },
      valueSetter: (params) => {
        const account = params.value as Account | null;
        return { ...params.row, assignedCategory: account?.id || null } as Document;
      },
      renderCell: (params) => {
        if (!organization) {
          return null;
        }

        const document = params.row as Document;

        const documentLoading = documentsLoading.has(document.id);

        let accounts: Account[];
        if (!document.date || !accountsByFy[getFiscalYear(document.date, organization.fyEndMonth)]) {
          accounts = [];
        } else {
          accounts = accountsByFy[getFiscalYear(document.date, organization.fyEndMonth)];
        }

        return (
          <Tooltip title={document.assignedCategory ? journalAccountsById[document.assignedCategory]?.name : 'None'} placement='left'>
            <TreeSelectDataGridCellView disabled={documentLoading || !!matchesByDocumentId[document.id]} params={params} items={accounts} />
          </Tooltip>
        );
      },
      renderEditCell: (params) => {
        if (!organization) {
          return null;
        }

        const document = params.row as Document;

        const documentLoading = documentsLoading.has(document.id);

        let accounts: Account[];
        if (!document.date || !accountsByFy[getFiscalYear(document.date, organization.fyEndMonth)]) {
          accounts = [];
        } else {
          accounts = accountsByFy[getFiscalYear(document.date, organization.fyEndMonth)];
        }

        return (
          <TreeSelectDataGridCellEdit
            disabled={documentLoading}
            params={params}
            items={accounts}
            itemComparator={(a, b) => a.name.localeCompare(b.name)}
            noSelectionValue='none'
          />
        );
      },
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: tab === DocumentsTab.NEED_CLARIFICATION ? 336 : 320,
      renderCell: (params) => {
        const document = params.row as Document;

        const documentLoading = documentsLoading.has(document.id);

        return (
          <Stack direction='row' spacing={1}>
            <Tooltip title='View Categorization'>
              <span>
                <IconButton
                  disabled={!document.assignedCategory || documentLoading}
                  onClick={() => {
                    setCategorizationDialogOpenFor(document.id);
                  }}
                >
                  <Category2
                    size='1rem'
                    variant='Bold'
                    color={!document.assignedCategory || documentLoading ? theme.palette.text.disabled : theme.palette.primary.main}
                  />
                </IconButton>
              </span>
            </Tooltip>

            <Tooltip title='View Matches'>
              <span>
                <IconButton
                  disabled={!matchesByDocumentId[document.id] || documentLoading}
                  onClick={() => {
                    setTimeout(() => {
                      window.open(`/admin/matches?documentId=${document.id}`, '_blank');
                    }, 100);
                  }}
                >
                  <BackwardItem
                    variant='Bold'
                    size='1rem'
                    color={!matchesByDocumentId[document.id] || documentLoading ? theme.palette.text.disabled : theme.palette.primary.main}
                  />
                </IconButton>
              </span>
            </Tooltip>

            <Tooltip title='View Journal Entries'>
              <span>
                <IconButton
                  disabled={documentLoading}
                  onClick={() => {
                    setTimeout(() => {
                      viewJournalEntryForDocument(document);
                    }, 100);
                  }}
                >
                  <Book size='1rem' variant='Bold' color={documentLoading ? theme.palette.text.disabled : theme.palette.primary.main} />
                </IconButton>
              </span>
            </Tooltip>

            <Tooltip title='Edit'>
              <span>
                <IconButton disabled={documentLoading} onClick={() => setEditDocument(document)}>
                  <Edit variant='Bold' size='1rem' color={documentLoading ? theme.palette.text.disabled : theme.palette.primary.main} />
                </IconButton>
              </span>
            </Tooltip>

            <Tooltip title='View Notes'>
              <Badge
                badgeContent={document.notes.length}
                color='primary'
                overlap='circular'
                slotProps={{
                  badge: {
                    style: {
                      background: theme.palette.background.default,
                      border: `1px solid ${theme.palette.primary.main}`,
                      color: theme.palette.primary.main,
                      fontSize: '0.4rem',
                      width: 12,
                      minWidth: 12,
                      height: 12,
                      padding: 0,
                    },
                  },
                }}
              >
                <IconButton disabled={documentLoading} onClick={() => setViewNotesForDocumentId(document.id)}>
                  <Notepad2 variant='Bold' size='1rem' color={documentLoading ? theme.palette.text.disabled : theme.palette.primary.main} />
                </IconButton>
              </Badge>
            </Tooltip>

            <Tooltip title={document.ignoreMatchRequirement ? 'Unignore Transaction Requirement' : 'Ignore Transaction Requirement'}>
              <span>
                <IconButton
                  disabled={documentLoading || (!documentsMissingTransactions?.has(document.id) && !document.ignoreMatchRequirement)}
                  onClick={async () => {
                    if (document.ignoreMatchRequirement) {
                      await unignoreMatchRequirement(document.id);
                    } else {
                      await ignoreMatchRequirement(document.id);
                    }
                  }}
                >
                  {!document.ignoreMatchRequirement && (
                    <svg
                      style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '1rem',
                        height: '1rem',
                      }}
                      viewBox='0 0 100 100'
                    >
                      <line
                        x1='100'
                        y1='10'
                        x2='10'
                        y2='100'
                        strokeWidth='1px'
                        stroke={theme.palette.background.default}
                        vectorEffect='non-scaling-stroke'
                      />
                      <line
                        x1='100'
                        y1='0'
                        x2='0'
                        y2='100'
                        strokeWidth='1px'
                        stroke={
                          documentLoading || (!documentsMissingTransactions?.has(document.id) && !document.ignoreMatchRequirement)
                            ? theme.palette.text.disabled
                            : theme.palette.primary.main
                        }
                        vectorEffect='non-scaling-stroke'
                      />
                    </svg>
                  )}
                  <ArrowSwapHorizontal
                    size='1rem'
                    variant='Bold'
                    color={
                      documentLoading || (!documentsMissingTransactions?.has(document.id) && !document.ignoreMatchRequirement)
                        ? theme.palette.text.disabled
                        : theme.palette.primary.main
                    }
                  />
                </IconButton>
              </span>
            </Tooltip>

            <Tooltip title={document.ignored ? 'Make Active' : 'Ignore'}>
              <span>
                <IconButton
                  onClick={() => changeDocument(document, { id: document.id, ignored: !document.ignored })}
                  disabled={!!matchesByDocumentId[document.id] || documentLoading}
                >
                  {document.ignored ? (
                    <Eye
                      variant='Bold'
                      size='1rem'
                      color={matchesByDocumentId[document.id] || documentLoading ? theme.palette.text.disabled : theme.palette.primary.main}
                    />
                  ) : (
                    <EyeSlash
                      variant='Bold'
                      size='1rem'
                      color={matchesByDocumentId[document.id] || documentLoading ? theme.palette.text.disabled : theme.palette.primary.main}
                    />
                  )}
                </IconButton>
              </span>
            </Tooltip>

            {document.otterProcessingStatus === OtterProcessingStatus.REVIEWED && (
              <Tooltip title='Mark as unreviewed'>
                <span>
                  <IconButton disabled={documentLoading || !!matchesByDocumentId[document.id]} onClick={() => markDocumentAsUnreviewed(document.id)}>
                    <InfoCircle
                      variant='Bold'
                      size='1rem'
                      color={documentLoading || !!matchesByDocumentId[document.id] ? theme.palette.text.disabled : theme.palette.primary.main}
                    />
                  </IconButton>
                </span>
              </Tooltip>
            )}
            {tab === DocumentsTab.NEED_CLARIFICATION && (
              <Tooltip title='Approve'>
                <span>
                  <IconButton
                    onClick={() => checkInSignOff(document.id)}
                    disabled={documentLoading || Object.keys(document.issues).length > 0 || document.checkInStatus === CheckInStatus.SCHEDULED}
                  >
                    <TickCircle
                      variant='Bold'
                      size='1rem'
                      color={
                        documentLoading || Object.keys(document.issues).length > 0 || document.checkInStatus === CheckInStatus.SCHEDULED
                          ? theme.palette.text.disabled
                          : theme.palette.primary.main
                      }
                    />
                  </IconButton>
                </span>
              </Tooltip>
            )}
            {document.otterProcessingStatus === OtterProcessingStatus.PARSED && (
              <Tooltip title='Approve'>
                <span>
                  <IconButton
                    onClick={() => approveDocument(document.id)}
                    disabled={
                      !document.date ||
                      !document.currency ||
                      !document.assignedCategory ||
                      (document.alreadyPaid && document.paymentObligation) ||
                      (document.type === DocumentType.RECEIPT && !document.alreadyPaid) ||
                      documentLoading
                    }
                  >
                    <TickCircle
                      variant='Bold'
                      size='1rem'
                      color={
                        !document.date ||
                        !document.currency ||
                        !document.assignedCategory ||
                        (document.alreadyPaid && document.paymentObligation) ||
                        (document.type === DocumentType.RECEIPT && !document.alreadyPaid) ||
                        documentLoading
                          ? theme.palette.text.disabled
                          : theme.palette.primary.main
                      }
                    />
                  </IconButton>
                </span>
              </Tooltip>
            )}
          </Stack>
        );
      },
    },
  ];

  if (tab === DocumentsTab.NEED_CLARIFICATION) {
    const clarificationColumns: GridColDef[] = [
      {
        field: 'issues',
        headerName: 'Issues',
        width: 64,
        align: 'center' as GridAlignment,
        type: 'string',
        renderCell: (params: GridRenderCellParams) => {
          const document = params.row as Document;
          const hasIssues = Object.keys(document.issues).length > 0;

          if (!hasIssues) {
            return null;
          }

          const issues = [];
          if (document.issues.uncategorized) {
            issues.push('Uncategorized');
          }
          if (document.issues.unmatched) {
            issues.push('Unmatched');
          }

          return (
            <Tooltip
              title={
                <>
                  {issues.map((i) => (
                    <span key={i} style={{ display: 'block' }}>
                      {i}
                    </span>
                  ))}
                </>
              }
              placement='left'
            >
              <Warning2 color={theme.palette.warning.main} />
            </Tooltip>
          );
        },
      },
      {
        field: 'checkIn',
        headerName: 'Check-In',
        width: 64,
        align: 'center' as GridAlignment,
        type: 'string',
        renderCell: (params: GridRenderCellParams) => {
          const document = params.row as Document;

          if (document.checkInStatus === CheckInStatus.SCHEDULED) {
            return (
              <Tooltip title='Scheduled for Check-In'>
                <CalendarCircle color={theme.palette.primary.main} />
              </Tooltip>
            );
          }

          if (document.checkInStatus === CheckInStatus.SAVED_FOR_LATER) {
            return (
              <Tooltip title='Saved for Later'>
                <Clock color={theme.palette.primary.main} />
              </Tooltip>
            );
          }

          if (document.checkInStatus === CheckInStatus.CLARIFIED) {
            return (
              <Tooltip title='Clarified'>
                <IconButton onClick={() => setCheckInResultsDialogOpenFor(document)}>
                  <TickCircle color={theme.palette.primary.main} />
                </IconButton>
              </Tooltip>
            );
          }

          return null;
        },
      },
    ];

    columns.splice(columns.length - 1, 0, ...clarificationColumns);
  }

  return (
    <>
      <DataGrid
        loading={!documents || !organization || !documentsMissingTransactions || !users}
        style={style}
        rows={documents || []}
        rowHeight={64}
        columns={columns}
        pageSizeOptions={[50]}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 50,
            },
          },
          sorting: {
            sortModel: [{ field: 'date', sort: 'desc' }],
          },
        }}
        onCellClick={handleCellClick}
        cellModesModel={cellModesModel}
        onCellModesModelChange={handleCellModesModelChange}
        isCellEditable={(params) => {
          const document = params.row as Document;

          return !!params.colDef.editable && !documentsLoading.has(document.id) && !matchesByDocumentId[document.id];
        }}
        processRowUpdate={async (newRow, oldRow) => {
          const old = oldRow as Document;
          const updated = newRow as Document;

          let result: 'QUEUED' | 'CHANGED' = 'CHANGED';
          if (updated.assignedCategory && updated.assignedCategory !== old.assignedCategory) {
            result = await changeDocumentOptimistic(old, updated);
          }

          if (result === 'CHANGED') {
            return updated;
          } else {
            return old;
          }
        }}
      />
      <DocumentEditingDialog
        open={!!editDocument}
        document={editDocument}
        matchesByDocumentId={matchesByDocumentId}
        organization={organization!}
        users={users! || []}
        accountsByFy={accountsByFy}
        onClose={() => setEditDocument(null)}
        onSave={async (changes) => {
          await changeDocument(editDocument!, changes);
        }}
      />
      <DocumentUpdateConfirmDialog
        documents={documents}
        matchesByDocumentId={matchesByDocumentId}
        documentChanges={queuedDocumentChanges}
        onClose={() => setQueuedDocumentChanges(null)}
        onConfirm={confirmDocumentChanges}
      />
      <DocumentCategorizationDialog
        open={!!categorizationDialogOpenFor}
        onClose={() => setCategorizationDialogOpenFor(null)}
        documentId={categorizationDialogOpenFor}
      />
      <DocumentNotesDialog
        organizationId={organization?.id || null}
        openForDocumentId={viewNotesForDocumentId || null}
        onClose={() => setViewNotesForDocumentId(null)}
      />
      <CheckInResultsDialog
        openFor={checkInResultsDialogOpenFor}
        organization={organization}
        accountsByFy={accountsByFy}
        onClose={() => setCheckInResultsDialogOpenFor(null)}
      />
    </>
  );
}

interface FileArchiveList {
  organization: Organization | null;
  style?: CSSProperties;
}

function FileArchiveList({ organization, style }: FileArchiveList) {
  const theme = useTheme();
  const { fileArchives, fetchFileArchives } = useAdmin();

  const [idCopied, setIdCopied] = useState<string | null>(null);
  const copy = useCallback(async (archiveId: string) => {
    setIdCopied(archiveId);
    await navigator.clipboard.writeText(archiveId);
  }, []);

  const archiveDownloadUrl = (archive: FileArchive) => `${window.location.protocol}${window.location.host}/file-archives/${archive.id!}`;
  const [linkCopied, setLinkCopied] = useState<string | null>(null);
  const copyLink = useCallback(async (archive: FileArchive) => {
    setLinkCopied(archive.id!);
    await navigator.clipboard.writeText(archiveDownloadUrl(archive));
  }, []);

  const [loading, setLoading] = useState(false);

  const orgFileArchives = useMemo(() => {
    if (!organization) {
      return [];
    }

    return fileArchives[organization.id!] || [];
  }, [organization, fileArchives]);

  useEffect(() => {
    if (!organization) {
      return;
    }

    fetchFileArchives(organization.id!).catch((e) => {
      throw e;
    });

    const interval = setInterval(() => {
      try {
        setLoading(true);
        fetchFileArchives(organization.id!).catch((e) => {
          throw e;
        });
      } finally {
        setLoading(false);
      }
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, [fetchFileArchives, organization]);

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      width: 50,
      renderCell: (params) => {
        const archive = params.row as FileArchive;

        return (
          <Tooltip title={archive.id}>
            <span>
              <IconButton onClick={() => copy(archive.id!)}>
                {idCopied === archive.id ? (
                  <CopySuccess size='1.1rem' variant='Bold' color={theme.palette.primary.main} />
                ) : (
                  <Copy size='1.1rem' variant='Outline' color={theme.palette.primary.main} />
                )}
              </IconButton>
            </span>
          </Tooltip>
        );
      },
    },
    {
      field: 'name',
      headerName: 'Name',
    },
    {
      field: 'type',
      headerName: 'Type',
    },
    {
      field: 'status',
      headerName: 'Status',
    },
    {
      field: 'created',
      headerName: 'Created',
      renderCell: (params) => {
        const archive = params.row as FileArchive;

        return format(archive.created, 'MMM d, yyyy');
      },
    },
    {
      field: 'actions',
      headerName: 'Actions',
      renderCell: (params) => {
        const archive = params.row as FileArchive;

        return (
          <Stack direction='row' spacing={1}>
            <Tooltip title='Download'>
              <span>
                <IconButton
                  disabled={archive.status === FileArchiveStatus.IN_PROGRESS || !archive.signedUrl}
                  onClick={() => {
                    window.open(archive.signedUrl!, '_blank');
                  }}
                >
                  <Download />
                </IconButton>
              </span>
            </Tooltip>

            <Tooltip title='Copy Download Link'>
              <span>
                <IconButton
                  disabled={archive.status === FileArchiveStatus.IN_PROGRESS || !archive.signedUrl}
                  onClick={async () => {
                    await copyLink(archive);
                  }}
                >
                  <LinkCircle
                    variant={linkCopied === archive.id ? 'Bold' : 'Outline'}
                    color={archive.status === FileArchiveStatus.IN_PROGRESS || !archive.signedUrl ? undefined : theme.palette.primary.main}
                  />
                </IconButton>
              </span>
            </Tooltip>
          </Stack>
        );
      },
    },
  ];

  if (!fileArchives.length && loading) {
    return <CircularProgress />;
  }

  return (
    <DataGrid
      style={style}
      rows={orgFileArchives}
      rowHeight={64}
      disableVirtualization={true}
      columns={columns}
      pageSizeOptions={[50]}
      initialState={{
        pagination: {
          paginationModel: {
            pageSize: 50,
          },
        },
        sorting: {
          sortModel: [{ field: 'created', sort: 'desc' }],
        },
      }}
    />
  );
}

function restrictLength(str: string, length: number, delimiter = ' ') {
  const tokenized = str.split(delimiter);

  let sum = 0;
  const approvedFrontParts = [] as string[];
  const approvedBackParts = [] as string[];

  if (tokenized.length === 1) {
    return str;
  }

  for (let i = 0; i < Math.ceil(tokenized.length / 2); i++) {
    if (i !== tokenized.length - 1 - i) {
      const frontPart = tokenized[i];
      if (sum + frontPart.length + 1 < length) {
        sum += frontPart.length;
        approvedFrontParts.push(frontPart);
      }
    }

    const backPart = tokenized[tokenized.length - 1 - i];
    if (sum + backPart.length + 1 < length) {
      sum += backPart.length;
      approvedBackParts.push(backPart);
    }
  }

  const frontJoined = approvedFrontParts.join(delimiter);
  const backJoined = approvedBackParts.join(delimiter);

  if (frontJoined + delimiter + backJoined === str) {
    return str;
  }

  return frontJoined + ' ... ' + backJoined;
}

const DocumentGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

function UploadDialog({
  open,
  onClose,
  onUploadDocuments,
}: {
  open: boolean;
  onClose: () => void;
  onUploadDocuments: (files: File[]) => Promise<void>;
}) {
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const [fileRejections, setFileRejections] = useState<string[]>([]);
  const [files, setFiles] = useState<File[] | null>(null);
  const { getRootProps, getInputProps, inputRef } = useDropzone({
    accept: {
      'image/heic': [],
      'image/jpeg': [],
      'image/png': [],
      'application/pdf': [],
    },
    maxSize: 5242880, // 5MB
    onDrop: (acceptedFiles, fileRejections) => {
      const rejectionData = [];

      for (const fileRejection of fileRejections) {
        for (const error of fileRejection.errors) {
          if (error.code === 'file-too-large') {
            rejectionData.push(`${fileRejection.file.name} is larger than 5 MB.`);
          }
          if (error.code === 'file-invalid-type') {
            rejectionData.push(`File type is not correct for this institution's statements.`);
          }
        }
      }

      setFileRejections(rejectionData);
      setFiles(acceptedFiles);
    },
  });

  useEffect(() => {
    setLoading(false);
    setFileRejections([]);
    setFiles(null);
  }, [open]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth='lg' fullWidth>
      <DialogTitle>
        <ThreeColumn $mainColumn align='center'>
          <span></span>
          <span>Upload Documents</span>
          <IconButton onClick={onClose}>
            <CloseCircle />
          </IconButton>
        </ThreeColumn>
      </DialogTitle>
      <DialogContent>
        <Stack>
          <Stack
            direction='column'
            alignItems='center'
            paddingX={SMALL_HORIZONTAL_SPACING}
            paddingTop={SMALL_VERTICAL_SPACING}
            paddingBottom={0}
            spacing={SMALL_VERTICAL_SPACING}
            {...getRootProps()}
            onClick={(e) => e.stopPropagation()}
          >
            <input style={{ display: 'none' }} {...getInputProps()} />

            <DocumentUpload size='6rem' color={theme.palette.primary.main} />

            <Typography variant='h3'>Drag and drop your file(s) here</Typography>
            <Typography>or</Typography>
            <Button
              variant='outlined'
              color='neutral'
              onClick={() => {
                inputRef.current!.click();
              }}
            >
              Browse for files
            </Button>
          </Stack>

          <DocumentGrid
            style={{
              paddingLeft: theme.spacing(SMALL_HORIZONTAL_SPACING),
              paddingRight: theme.spacing(SMALL_HORIZONTAL_SPACING),
              paddingBottom: theme.spacing(SMALL_VERTICAL_SPACING),
            }}
          >
            {(files || []).map((file, i) => (
              <Stack
                key={`${file.name}-${i}`}
                sx={{
                  background: theme.palette.background.default,
                }}
                borderRadius={theme.roundedCorners(5)}
                border={`1px solid ${theme.palette.neutral.main}`}
                direction='row'
                alignItems='center'
                paddingX={theme.spacing(SMALL_HORIZONTAL_SPACING)}
                paddingY={theme.spacing(SMALL_VERTICAL_SPACING)}
                justifyContent='space-between'
              >
                <Receipt size='1rem' style={{ flexShrink: 0 }} />
                <Tooltip title={file.name}>
                  <Typography>{restrictLength(file.name, 10, '-')}</Typography>
                </Tooltip>
                <IconButton
                  onClick={() => {
                    setFiles((existing) => {
                      return [...(existing || [])].filter((f) => f !== file);
                    });
                  }}
                >
                  <CloseCircle />
                </IconButton>
              </Stack>
            ))}
          </DocumentGrid>

          {!!fileRejections.length && (
            <Stack paddingX={SMALL_HORIZONTAL_SPACING} paddingY={SMALL_VERTICAL_SPACING}>
              {fileRejections.map((fileRejection) => (
                <Alert
                  icon={<Warning2 />}
                  severity='error'
                  style={{
                    alignItems: 'center',
                  }}
                  onClose={() => {
                    setFileRejections((existing) => [...existing].filter((r) => r !== fileRejection));
                  }}
                >
                  {fileRejection}
                </Alert>
              ))}
            </Stack>
          )}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant='contained' color='neutral' onClick={onClose}>
          Close
        </Button>

        <Button
          variant='contained'
          color='primary'
          disabled={!files}
          onClick={async () => {
            try {
              setLoading(true);
              await onUploadDocuments(files!);
            } finally {
              setLoading(false);
            }
          }}
        >
          {loading ? <CircularProgress /> : 'Upload'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

interface FilterConfig {
  reimbursementsOnly: boolean;
}

interface DocumentsListProps {
  tab: DocumentsTab;
  documents: Document[] | null;
  organization: Organization | null;
  users: User[] | null;
  journal: Journal | null;
  journalEntries: JournalEntry[] | null;
  accountsByFy: { [fy: string]: Account[] };
  matchesByDocumentId: { [documentId: string]: TransactionDocumentMatch[] };
  documentsMissingTransactions: Set<string> | null;
  filterConfig: FilterConfig;
  onChangeDocument: (oldDocument: Document, document: Partial<Document> & { id: string }) => Promise<void>;
  onPrepareDocumentArchive: () => Promise<void>;
  onViewDocumentArchives: () => void;
  onUploadDocuments: (files: File[]) => Promise<void>;
  onFilterConfigChange: (changes: FilterConfig) => void;
  documentFilter: (document: Document) => boolean;
}
function DocumentsList({
  tab,
  documents,
  documentsMissingTransactions,
  organization,
  users,
  journal,
  journalEntries,
  accountsByFy,
  matchesByDocumentId,
  filterConfig,
  onChangeDocument,
  onViewDocumentArchives,
  onPrepareDocumentArchive,
  onUploadDocuments,
  onFilterConfigChange,
  documentFilter,
}: DocumentsListProps) {
  const theme = useTheme();
  const [searchCriteria, setSearchCriteria] = useState<string[]>([]);

  const { documentIds } = useContext(AdminDocumentsPageUrlContext);

  useEffect(() => {
    if (documentIds.length) {
      setSearchCriteria(documentIds.map((id) => `id::${id}`));
    }
  }, [documentIds]);

  const appliedFilterCount = useMemo(() => {
    let count = 0;
    if (filterConfig.reimbursementsOnly) {
      count++;
    }
    return count;
  }, [filterConfig]);
  const [showFilterConfigMenu, setShowFilterConfigMenu] = useState(false);
  const filterConfigButtonRef = useRef<HTMLButtonElement | null>(null);

  const [uploadDialogOpen, setUploadDialogOpen] = useState(false);

  const [downloadMenuOpen, setDownloadMenuOpen] = useState(false);
  const downloadButtonRef = useRef<HTMLButtonElement | null>(null);

  const filteredDocuments = useMemo(() => {
    return documents ? searchDocuments(documents.filter(documentFilter), searchCriteria) : null;
  }, [documents, documentFilter, searchCriteria]);

  return (
    <>
      <Stack direction='row' alignItems='stretch'>
        <Search searchCriteria={searchCriteria} onSearchCriteriaUpdate={(searchCriteria) => setSearchCriteria(searchCriteria)} style={{ flex: 1 }} />

        <Tooltip title='Filter Settings'>
          <Badge badgeContent={appliedFilterCount} color='primary' overlap='circular'>
            <IconButton onClick={() => setShowFilterConfigMenu((existing) => !existing)} ref={filterConfigButtonRef}>
              <Filter color={theme.palette.primary.main} />
            </IconButton>
          </Badge>
        </Tooltip>
        <Menu
          open={showFilterConfigMenu}
          onClose={() => setShowFilterConfigMenu(false)}
          anchorEl={filterConfigButtonRef.current}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <Stack
            sx={{
              margin: theme.spacing(5),
            }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={filterConfig.reimbursementsOnly}
                  onChange={(e) => onFilterConfigChange({ ...filterConfig, reimbursementsOnly: e.target.checked })}
                />
              }
              label='Only show reimbursements'
            />
          </Stack>
        </Menu>

        <Tooltip title='Upload Document'>
          <Button variant='contained' color='primary' ref={downloadButtonRef} onClick={() => setUploadDialogOpen(true)}>
            <DocumentUpload />
          </Button>
        </Tooltip>

        <Tooltip title='Open Download Menu'>
          <Button
            variant='outlined'
            color='neutral'
            style={{ background: 'none' }}
            ref={downloadButtonRef}
            onClick={() => setDownloadMenuOpen((current) => !current)}
          >
            <Download />
          </Button>
        </Tooltip>

        <Popover
          open={downloadMenuOpen}
          onClose={() => setDownloadMenuOpen(false)}
          anchorEl={downloadButtonRef.current}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            horizontal: 'right',
            vertical: 'top',
          }}
        >
          <MenuList>
            <MenuItem onClick={onPrepareDocumentArchive}>
              <RefreshCircle style={{ marginRight: theme.spacing(MED_HORIZONTAL_SPACING) }} />
              <ListItemText>Prepare download archive</ListItemText>
            </MenuItem>
            <MenuItem onClick={onViewDocumentArchives}>
              <FolderZip style={{ marginRight: theme.spacing(MED_HORIZONTAL_SPACING) }} />
              <ListItemText>View prepared archives</ListItemText>
            </MenuItem>
          </MenuList>
        </Popover>

        <UploadDialog
          open={uploadDialogOpen}
          onClose={() => setUploadDialogOpen(false)}
          onUploadDocuments={async (files) => {
            await onUploadDocuments(files);
            setUploadDialogOpen(false);
          }}
        />
      </Stack>

      <DocumentsTable
        tab={tab}
        documents={filteredDocuments}
        organization={organization}
        users={users}
        journal={journal}
        journalEntries={journalEntries}
        accountsByFy={accountsByFy}
        matchesByDocumentId={matchesByDocumentId}
        documentsMissingTransactions={documentsMissingTransactions}
        onChangeDocument={onChangeDocument}
      />
    </>
  );
}

const AdminDocumentsPageUrlContext = createContext(
  {} as {
    documentIds: string[];
    updateDocumentIds: (documentIds: string[]) => void;
    tab: DocumentsTab;
    updateTab: (tab: DocumentsTab) => void;
  }
);

const AdminDocumentsPageUrlContextProvider = ({ children }: { children: React.ReactNode }) => {
  const DOCUMENT_IDS_PARAM = 'documentIds';
  const TAB_PARAM = 'tab';

  const [tab, setTab] = useState(DocumentsTab.UNREVIEWED);
  const [documentIds, setDocumentIds] = useState<string[]>([]);
  const navigate = useNavigate();
  const location = useLocation();
  const [pendingSearchParams, setPendingSearchParams] = useState<URLSearchParams | null>(null);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);

    const documentIdsParam = queryParams.getAll(DOCUMENT_IDS_PARAM);
    if (documentIdsParam) {
      setDocumentIds(documentIdsParam);
    }

    const tabParam = queryParams.get(TAB_PARAM);
    if (tabParam) {
      setTab(tabParam as DocumentsTab);
    }
  }, [location]);

  useEffect(() => {
    if (pendingSearchParams) {
      const searchParams = new URLSearchParams(location.search);

      for (const [key, value] of pendingSearchParams.entries()) {
        searchParams.set(key, value);
      }
      navigate({ search: searchParams.toString() });

      setPendingSearchParams(null);
    }
  }, [pendingSearchParams, navigate, location.search]);

  const clearSearchParam = useCallback(
    (param: string) => {
      setPendingSearchParams((previous) => {
        const searchParams = new URLSearchParams(location.search);

        if (previous) {
          for (const [key, value] of previous.entries()) {
            searchParams.set(key, value);
          }
        }

        searchParams.delete(param);

        return searchParams;
      });
    },
    [location]
  );

  const updateDocumentIds = useCallback(
    (transactionIds: string[]) => {
      if (!transactionIds.length) {
        clearSearchParam(DOCUMENT_IDS_PARAM);
      } else {
        setPendingSearchParams((previous) => {
          const searchParams = new URLSearchParams(location.search);

          if (previous) {
            for (const [key, value] of previous.entries()) {
              searchParams.set(key, value);
            }
          }

          searchParams.delete(DOCUMENT_IDS_PARAM);
          for (const id of transactionIds) {
            searchParams.append(DOCUMENT_IDS_PARAM, id);
          }

          return searchParams;
        });
      }

      setDocumentIds(transactionIds);
    },
    [location, clearSearchParam]
  );

  const updateTab = useCallback(
    (tab: DocumentsTab | null) => {
      if (!tab) {
        clearSearchParam(TAB_PARAM);
      } else {
        setPendingSearchParams((previous) => {
          const searchParams = new URLSearchParams(location.search);

          if (previous) {
            for (const [key, value] of previous.entries()) {
              searchParams.set(key, value);
            }
          }

          searchParams.set(TAB_PARAM, tab);

          return searchParams;
        });
      }
    },
    [location, clearSearchParam]
  );

  return (
    <AdminDocumentsPageUrlContext.Provider value={{ documentIds, updateDocumentIds, tab, updateTab }}>
      {children}
    </AdminDocumentsPageUrlContext.Provider>
  );
};

function AdminDocumentsPageContent({ ...props }) {
  const theme = useTheme();
  const { tab, updateTab: setTab } = useContext(AdminDocumentsPageUrlContext);
  const { selectedOrganization: selectedOrg, setSelectedOrganization: setSelectedOrg, previousSelectedOrganization } = useSelectedOrganization(null);
  const [selectedJournal, setSelectedJournal] = useState<Journal | null>(null);
  const [fileArchiveDialogOpen, setFileArchiveDialogOpen] = useState(false);

  const {
    organizations,
    users,
    journals,
    journalEntries,
    accountsByFy,
    documents,
    matchesByDocumentId,
    updateDocument,
    prepareDocumentArchive,
    documentsMissingTransactions,
    uploadDocuments,
    unreviewedDocumentCounts,
  } = useAdminData(selectedOrg, selectedJournal);

  const [filterConfig, setFilterConfig] = useState<FilterConfig>({
    reimbursementsOnly: false,
  });

  const documentFilter = useMemo(() => {
    const firstJournalFyStart = journals.length ? journals.map((j) => j.fyStart).sort((a, b) => a.getTime() - b.getTime())[0] : null;
    const lastJournalFyEnd = journals.length ? journals.map((j) => j.fyEnd).sort((a, b) => b.getTime() - a.getTime())[0] : null;

    const dateAllowed = (document: Document) => {
      if (!document.date || !firstJournalFyStart || !lastJournalFyEnd) {
        return true;
      } else if (!selectedJournal && (document.date < firstJournalFyStart || document.date > lastJournalFyEnd)) {
        return true;
      } else if (selectedJournal && document.date >= selectedJournal.fyStart && document.date <= selectedJournal.fyEnd) {
        return true;
      }

      return false;
    };

    const filterConfigAllowed = (document: Document) => {
      if (filterConfig.reimbursementsOnly && !document.reimbursement) {
        return false;
      }

      return true;
    };

    switch (tab) {
      case DocumentsTab.ALL:
        return (document: Document) => dateAllowed(document) && filterConfigAllowed(document);
      case DocumentsTab.ALL_ACTIVE:
        return (document: Document) => !document.ignored && dateAllowed(document) && filterConfigAllowed(document);
      case DocumentsTab.IGNORED:
        return (document: Document) => document.ignored && dateAllowed(document) && filterConfigAllowed(document);
      case DocumentsTab.UNREVIEWED:
        return (document: Document) =>
          document.otterProcessingStatus === OtterProcessingStatus.PARSED &&
          !document.ignored &&
          dateAllowed(document) &&
          filterConfigAllowed(document);
      case DocumentsTab.NEED_CLARIFICATION:
        return (document: Document) =>
          dateAllowed(document) &&
          filterConfigAllowed(document) &&
          (document.checkInStatus === CheckInStatus.SCHEDULED ||
            document.checkInStatus === CheckInStatus.SAVED_FOR_LATER ||
            document.checkInStatus === CheckInStatus.CLARIFIED ||
            (Object.keys(document.issues).length > 0 && document.otterProcessingStatus === OtterProcessingStatus.REVIEWED && !document.ignored));
      case DocumentsTab.REVIEWED:
        return (document: Document) =>
          Object.keys(document.issues).length === 0 &&
          document.otterProcessingStatus === OtterProcessingStatus.REVIEWED &&
          !document.ignored &&
          dateAllowed(document) &&
          filterConfigAllowed(document);
    }
  }, [tab, selectedJournal, journals, filterConfig]);

  const prepareArchive = useCallback(async () => {
    await prepareDocumentArchive();
    setFileArchiveDialogOpen(true);
  }, [prepareDocumentArchive]);

  if (!journals || !organizations) {
    return (
      <PageContainer {...props}>
        <PageHeader title='Admin - Documents' />
        <PageBody gutter='thin' style={{ alignItems: 'center', justifyContent: 'center' }}>
          <CircularProgress />
        </PageBody>
      </PageContainer>
    );
  }

  return (
    <PageContainer {...props}>
      <PageHeader title='Admin - Documents' />
      <PageBody gutter='thin'>
        <Stack height='100%'>
          <Stack direction='row' paddingTop={theme.spacing(2)}>
            <AdminOrganizationSelect
              organizations={organizations}
              onOrganizationChange={(org) => {
                setSelectedOrg(org);
                if (selectedOrg?.id !== previousSelectedOrganization?.id) {
                  setSelectedJournal(null);
                }
              }}
            />

            <AdminJournalSelect
              showNone
              unreviewedCounts={unreviewedDocumentCounts}
              journals={journals}
              organization={selectedOrg}
              selectedJournal={selectedJournal}
              onJournalChange={(j) => setSelectedJournal(j)}
            />
          </Stack>

          <Tabs
            value={tab}
            onChange={(_e, newValue: DocumentsTab) => {
              setTab(newValue);
            }}
          >
            <Tab
              label={
                unreviewedDocumentCounts && selectedJournal && unreviewedDocumentCounts[selectedJournal.fy]
                  ? `Unreviewed (${unreviewedDocumentCounts[selectedJournal.fy]})`
                  : `Unreviewed`
              }
              value={DocumentsTab.UNREVIEWED}
            />
            <Tab label='Need Clarification' value={DocumentsTab.NEED_CLARIFICATION} />
            <Tab label='Reviewed' value={DocumentsTab.REVIEWED} />
            <Tab label='All Active' value={DocumentsTab.ALL_ACTIVE} />
            <Tab label='Ignored' value={DocumentsTab.IGNORED} />
            <Tab label='All' value={DocumentsTab.ALL} />
          </Tabs>

          {(!selectedOrg || !selectedJournal) && (
            <Stack justifyContent='center' alignItems='center' flex={1}>
              <Typography>Please select Organization and FY</Typography>
            </Stack>
          )}

          {selectedOrg && selectedJournal && (
            <DocumentsList
              tab={tab}
              documents={documents}
              organization={selectedOrg}
              users={users}
              journal={selectedJournal}
              journalEntries={journalEntries}
              accountsByFy={accountsByFy}
              matchesByDocumentId={matchesByDocumentId}
              documentsMissingTransactions={documentsMissingTransactions}
              onChangeDocument={updateDocument}
              onPrepareDocumentArchive={prepareArchive}
              onViewDocumentArchives={() => setFileArchiveDialogOpen(true)}
              onUploadDocuments={uploadDocuments}
              documentFilter={documentFilter}
              filterConfig={filterConfig}
              onFilterConfigChange={(changes) => setFilterConfig(changes)}
            />
          )}

          <Dialog open={fileArchiveDialogOpen} onClose={() => setFileArchiveDialogOpen(false)} fullScreen>
            <DialogTitle>File Archives</DialogTitle>
            <DialogContent>
              <FileArchiveList organization={selectedOrg} />
            </DialogContent>
            <DialogActions>
              <Button variant='contained' color='neutral' onClick={() => setFileArchiveDialogOpen(false)}>
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </Stack>
      </PageBody>
    </PageContainer>
  );
}

export function AdminDocumentsPage({ ...props }) {
  return (
    <AdminDocumentsPageUrlContextProvider>
      <AdminDocumentsPageContent {...props} />
    </AdminDocumentsPageUrlContextProvider>
  );
}
